import React from "react";

function ReactionsCount(props){

   
    return(

        props.accountDetails.map(count=>(
        <div className="users-posts-reactions">

        <div className="reactions">
            <h4>Followers</h4>
            <h4>{count.followers}</h4>
        </div>

        <div className="reactions">
            <h4>Following</h4>
            <h4>{count.following}</h4>
        </div>

        <div className="reactions">
            <h4>Likes</h4>
            <h4>...</h4>
        </div>

    </div>
         ) )
    )
}

export default ReactionsCount