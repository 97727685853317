import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import { BiUpvote } from "react-icons/bi";
import { BiSolidUpvote } from "react-icons/bi";
import { Comment } from  'react-loader-spinner'
import axios from "axios";

function LikeComment(props) {

    const user = useContext(UserContext);

    const [commentLikes, setCommentLikes] = useState(props.likeCount)
    const [likedStatus, setLikedStatus] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(

        () => {

            const likeStatus = async () => {



                try {

                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/likeComment/likedetails?details={"email":"${user.email}","comment_id":"${props.comment_id}"}`)


                    if (response.data.length === 0) {
                        setLikedStatus(false)
                    }
                    else {
                        setLikedStatus(true)
                    }


                    setLoading(false)

                }
                catch (err) {

                    setLoading(false)
                }
            }
            likeStatus()


        }, [])

    const handleLike = async () => {

        console.log(props.comment_id)
        console.log(user.email)

        const commentDetails = {
            email: user.email,
            comment_id: props.comment_id
        }

        try {

            const response = await axios.post(

                `${process.env.REACT_APP_API_URL}/api/v1/likeComment/commentlike/`,
                commentDetails)

            setLikedStatus((prevState) => !prevState)




            setLoading(false)

        }
        catch (err) {

            setLoading(false)
        }
    }

    const like = () => {
        setCommentLikes(prevLikes => prevLikes + 1);
        console.log(commentLikes)
    }

    const unLike = () => {
        
        setCommentLikes(prevLikes => prevLikes - 1);
    }

    useEffect(() => {

    }, [commentLikes]);

    

    return (
        loading ?     
    
    
        <Comment
        visible={true}
        height="200"
        width="200"
        ariaLabel="comment-loading"
        wrapperStyle={{}}
        wrapperClass="comment-wrapper"
        color="#fff"
        backgroundColor=" #00b41e"
      />
     
            :
            likedStatus
                ? <div className="comment-votes" onClick={() => { unLike(); handleLike(); }}>
                    <BiSolidUpvote size="1.5em" color="#F47325" />
                    <div>{commentLikes}</div>
                </div>
                : <div className="comment-votes" onClick={() => { like(); handleLike(); }}>
                    <BiUpvote size="1.5em" color="#000" />
                    <div>{commentLikes}</div>
                </div>
    );

}

export default LikeComment