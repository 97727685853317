import React from 'react';
import './App.css';
import MainNavigationMenu from './components/MainNavigationMenu';
import {Route,Routes} from 'react-router-dom'
import Home from './components/Home';
import VideosPage from './components/VideosPage';
import WallOfFame from './components/WallOfFame';
import Account from './components/Account';
import Header from './components/Header';
import TopPost from './components/posts/TopPost';
import TopComment from './components/TopComment';
import { Rings } from  'react-loader-spinner'
import axios from 'axios'



import { useState,useEffect } from 'react';
import VideoTest from './VideoTest';
import Toptenposts from './components/posts/Toptenposts';
import ForYou from './components/posts/ForYou';
import Global from './components/posts/Global';
import TestL from './TestL';
import PostById from './components/posts/PostById';
import HomePage from './HomePage';
import Allanoadminhappy from './components/admin/Allanoadminhappy';
import TotalViews from './components/admin/TotalViews';
import MemeBattles from './components/monetisation/MemeBattles';
import EmailUsers from './components/admin/EmailUsers';
import GiftBox from './components/monetisation/GiftBox';
import Policies from './components/PrivacyAndPlocies/Policies';





export const UserContext=React.createContext()

// import HeaderNavigation from './components/HeaderNavigation';
// import LocalContent from './components/LocalContent';



function App() {

  //let status=false

  

  const [user, setUser] = useState(null)
  const[status,setStatus]=useState(true)
  const[loading,setLoading]=useState(true)
  const[userCountry,setUserCountry]=useState([])
  const [homeloading,setHomeLoading]=useState(true)
  const [installPromptVisible, setInstallPromptVisible] = useState(false);

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  
  // Function to fetch user's country from API
  const fetchUserCountry = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/country/location`);
      
      console.log(response.data)
      setUserCountry(response.data.country)
      console.log(response.data.Ip)

      // Store the country data in localStorage
      localStorage.setItem('country', JSON.stringify(response.data.country));
      localStorage.setItem('ip', JSON.stringify(response.data.Ip));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (typeof localStorage !== 'undefined') {
  //     const currentCount = localStorage.getItem('visitCount');
  //     const visitCount = currentCount ? parseInt(currentCount) : 0;
  
  //     if (visitCount <= 3) {
  //       const timer = setTimeout(() => {
  //         setStatus(false);
  //         setHomeLoading(false)
  //       }, 20000);
  
  //       return () => {
  //         clearTimeout(timer);
  //       };
  //     }
  //   }
  // }, []);
  
  useEffect(() => {
    // Check if country data exists in localStorage
    const storedCountry = localStorage.getItem('country');

    if (storedCountry) {
      // If data exists, parse and set it
      setUserCountry(JSON.parse(storedCountry));
  

      setLoading(false);
    } else {
      // If data doesn't exist, fetch it from API
      fetchUserCountry();
      
    }
  }, []);




	const getUser = async () => {
		try {
      
			
			const url = `${process.env.REACT_APP_API_URL}/auth/login/success`;
			const { data } = await axios.get(url, { withCredentials: true });
		   setUser(data.user._json);

       
       if(data.user._json){

                    // Retrieve 'country' from local storage
                    const storedCountry = localStorage.getItem('country');
            
        
                    // Set a default value if 'country' is not present
                    const country = storedCountry !== null ? storedCountry : 'global';
                
                    // Encode the 'country' value to ensure it's URL-safe
                    const encodedCountry = encodeURIComponent(country);

        try {

          const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/details/updateLocation?locationDetails={"email":"${data.user._json.email}","country":${encodedCountry}}`)


          

      }
      catch (err) {

          
      }
       }
		} catch (err) {
			
		}
	};

	useEffect(() => {
		getUser();
    
	}, []);
  

// useEffect(() => {
//     const handleBeforeInstallPrompt = event => {
//       // Prevent the default browser install prompt
//       event.preventDefault();

//       // Store the event for later use
//       // deferredPrompt = event;

//       setDeferredPrompt(event)

//       // Show the install prompt
//       setInstallPromptVisible(true);
//     };

//     // Listen for the beforeinstallprompt event
//     window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

//     // Clean up event listener when the component unmounts
//     return () => {
//       window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
//     };
//   }, []); // Empty dependency array ensures this effect runs once on component mount

//   const handleInstallClick = () => {
//     if (deferredPrompt) {
//       // Show the browser's native install prompt
//       deferredPrompt.prompt();

//       // Wait for the user to respond to the prompt
//       deferredPrompt.userChoice.then(choiceResult => {
//         if (choiceResult.outcome === 'accepted') {
//           console.log('User accepted the install prompt');
//         } else {
//           console.log('User dismissed the install prompt');
//         }
//         // Reset deferredPrompt for future use
//         setDeferredPrompt(null)
//       });
//     }
//     // Hide the install button
//     setInstallPromptVisible(false);
//   };




  return (
    
    status ?
   <UserContext.Provider  value={user}>
    <>
    <Header />




    <MainNavigationMenu />

    {/* {installPromptVisible && (
          <button onClick={handleInstallClick} className='install-btn'>Install App</button>
        )} */}

  {loading ?
  (
  <div className='home-loading'>
  <Rings
height="500"
width="500"
color="#4fa94d"
radius="6"
wrapperStyle={{}}
wrapperClass=""
visible={true}
ariaLabel="rings-loading"
/> 
</div>
  )
  :
  (
    <Routes >
      <Route path="/" element={<Home />} />

      <Route path="/wallOfFame" element={<WallOfFame />} />

      <Route path="/videosPage" element={<VideosPage/>}/>

      <Route path="/account" element={<Account />} />

      <Route path="/toppost" element={<TopPost />} />

      <Route path="/toptenposts" element={<Toptenposts />} />

      <Route path="/topcomment" element={<TopComment />} />

      <Route path="/foryou" element={<ForYou />} /> 

      <Route path="/global" element={<Global />} /> 

      <Route path="/postbyid" element={<PostById />} /> 

      <Route path="/allano" element={<Allanoadminhappy />} /> 

      <Route path="/allanoTotalViews" element={<TotalViews />} /> 

      <Route path="/memebattle" element={<MemeBattles />} /> 

      <Route path="/email" element={<EmailUsers />} /> 
      <Route path="/giftbox" element={<GiftBox />} /> 
      <Route path="/policies" element={<Policies />} /> 
      <Route path="/testui" element={<TestL />} /> 

      <Route path="*" element={<TestL />} />
    </Routes>
  )
    // <TestL />
}
   
    </>

     </UserContext.Provider>
    :
<HomePage setStatus={setStatus}/>
    
    // <VideoTest />
    
  );
}

export default App;
