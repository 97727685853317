import React from 'react'
import HeaderNavigationRoutes from './HeaderNavigationRoutes';
import VideosPosts from './VideosPosts';

function VideosPage(){
    return(
        <>
        
            <VideosPosts />
        </>
    )
}

export default VideosPage