import React,{useState,useContext,useEffect} from "react";
import img1 from '../images/profile-min.JPG'
import {VscKebabVertical} from 'react-icons/vsc'
import { Link, Routes,Route } from "react-router-dom";
import Account from "./Account";
import FollowModal from './modal/FollowModal'
import {UserContext} from '../App'
import axios from "axios";


function ProfileDetails(props){
  const user = useContext(UserContext)

  const[followModal,setFollowModal]=useState(false)
  const[acDetails,setAccountDetails]=useState([])
  const[loading,setLoading]=useState(true)


useEffect(()=>{

    const fetchData = async () => {
        try {

            setLoading(true)

            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/posts/acDetails?userName=${props.email}`)

            

            setAccountDetails(response.data)
            setLoading(false)

        }
        catch (err) {

            
            setLoading(false)
        }
    }

    fetchData()

  },[])
    



    const handleDateTime=(dateTime)=>{
        // Time string to create a Date object
        const time = new Date(dateTime);
        
        // Get the current time
        const currentTime = new Date();
        
        // The difference between the times in milliseconds
        const diff = currentTime.getTime() - time.getTime();
        
        //  The difference in years, days, and hours
        const diffInYears = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
        const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
        const diffInHours = Math.floor(diff / (1000 * 60 * 60));
        const diffInMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
        
        
        // string indicating how long ago the time was
        let timeAgo;
        if (diffInYears > 0) {
         return timeAgo = `${diffInYears} year${diffInYears > 1 ? '' : ''} `;
        }
        else if(diffInMonths > 0) {
          return  timeAgo = `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
          }
        else if (diffInDays > 0) {
          return timeAgo = `${diffInDays} d${diffInDays > 1 ? '' : ''}`;
        } else if (diffInHours > 0) {
          return timeAgo = `${diffInHours} h${diffInHours > 1 ? '' : ''} `;
        } else {
         return timeAgo = 'just now';
        }
            }

    return(
        <>
        

        <div className="profile">
           
        <Link to={`/account?ud=${ loading?"...":acDetails[0].id}`} >
          {
            props.profile_picture_link==="1"?
           <div className="profile-photo"><img src={`https://res.cloudinary.com/dxkicctcv/image/upload/v1685291624/ofx2j9gkzncnmlf9cs33.png`} alt="profile-img"  /></div>
           :
           <div className="profile-photo"><img src={props.profile_picture_link} alt="profile-img"  /></div>
          }
           </Link>
           <Link to={`/account?ud=${ loading?"...":acDetails[0].id}`}  >
           <div className="username"><h5>{props.userName}</h5></div>
           </Link>

           {/* <div className="post-date-time"><h5>{handleDateTime(props.date_time)}</h5></div> */}
   
           <div className="kebab-menu" onClick={()=>setFollowModal(true)}>

           <VscKebabVertical color="#FFFDD0" />
           
           </div>
           {followModal && user && <FollowModal closeFollowModal={setFollowModal} email={props.email} />}
           </div>
           

       

        <Routes>

        <Route path="/account" element={<Account />} />
        </Routes>

        </>

        
    )
}

export default ProfileDetails