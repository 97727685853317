import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import img1 from "../images/default.jpeg";
import { UserContext } from "../App";
import PostComment from "./PostComment";
import axios from "axios";
import CommentsReplies from "./CommentsReplies";
import LikeComment from "./comments/LikeComment";
import { Comment } from  'react-loader-spinner'

function Comments(props) {
  const user = useContext(UserContext);

  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [commentSent, setCommentSent] = useState(false); // State to track whether comment is sent

  const fetchComments = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/posts/comments/${props.post_id}`
      );
      setComments(response.data);
      
      
      setLoading(false)
    } catch (err) {}
  }, [props.post_id]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments, commentSent]); // Re-fetch comments when commentSent changes

  const handleDateTime = (dateTime) => {
    // Time string to create a Date object
    const time = new Date(dateTime);

    // Get the current time
    const currentTime = new Date();

    // The difference between the times in milliseconds
    const diff = currentTime.getTime() - time.getTime();

    // The difference in years, days, and hours
    const diffInYears = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
    const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    const diffInHours = Math.floor(diff / (1000 * 60 * 60));

    // string indicating how long ago the time was
    let timeAgo;
    if (diffInYears > 0) {
      timeAgo = `${diffInYears} year${diffInYears > 1 ? "s" : ""} `;
    } else if (diffInDays > 0) {
      timeAgo = `${diffInDays} d${diffInDays > 1 ? "" : ""}`;
    } else if (diffInHours > 0) {
      timeAgo = `${diffInHours} h${diffInHours > 1 ? "" : ""} `;
    } else {
      timeAgo = "just now";
    }
    return timeAgo;
  };

  const decodeCommentText = (comment) => {
    
    const decodedComment = decodeURIComponent(comment);
   
    return decodedComment;
  };

  return (
    <div className="commentsModal">
      <button className="modal-btn" onClick={() => props.closeCommentsModal(false)}>
        X
      </button>
      <div className="parent-comment">
        {loading ? (
          <div id="comment-loading">
  <Comment
  visible={true}
  height="80"
  width="80"
  ariaLabel="comment-loading"
  wrapperStyle={{}}
  wrapperClass="comment-wrapper"
  color="#fff"
  backgroundColor=" #00b41e"
/>
</div>
        ) : (
          <>
            {comments.map((comment) => (
              <div key={comment.comment_id} className="comment-box">
                <div className="comments-profile">
                  <div className="profile-image">
                    <img src={img1} alt="profile-img" />
                  </div>
                  <div className="username">
                    <h5>{comment.username}</h5>
                  </div>
                </div>
                <div className="comment-vote">
                  <div className="individual-comment">
                    {decodeCommentText(comment.comment_text)}
                  </div>
                          <LikeComment comment_id={comment.comment_id} post_id={props.post_id} likeCount={comment.count}  />
                </div>

                <div className="date-reply">
                  <div className="date">{handleDateTime(comment.date_time)}</div>
                  {/* <div className="reply">Reply</div> */}
                </div>
                {/* <div className="read-replies">
                  <CommentsReplies comment_id={comment.comment_id} />
                </div> */}
              </div>
            ))}
          </>
        )}
      </div>
      <PostComment post_id={props.post_id} commentSent={commentSent} setCommentSent={setCommentSent} />
    </div>
  );
}

export default Comments;
