import React, { useState, useEffect } from "react"
import axios from 'axios'
import Tv from "../Tv"
import ProfileDetails from "../ProfileDetails"
import { useLocation } from 'react-router-dom';


function PostById() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const  p_id= searchParams.get('post_id')



  const [posts, setPost] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {


      try {
        const Response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/posts/singlePost/${p_id}`
        )

        setPost(Response.data)

        setLoading(false)


      } catch (error) {
        setPost({})
        setLoading(false)
      }
    };

    fetchData()
  }, [])



  return (
    <div >



      {
        loading ? 'Loading....' :
          posts.map(post => (
            <div className="post-parent" key={post.post_id}>

              <ProfileDetails profile_picture_link={post.profile_picture_link} userName={post.username} date_time={post.date_time} email={post.email} />

              <Tv file_link={post.file_link} file_type={post.file_type} post_id={post.post_id} numberOfVotes={post.numberOfVotes} />

            </div>
          ))
      }

    </div>
  )
}

export default PostById