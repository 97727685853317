import React, { useState, useEffect, Fragment } from 'react'
import { Link } from "react-router-dom";
import { nanoid } from 'nanoid'
import { Comment } from  'react-loader-spinner'
import axios from 'axios';

function TopComments() {

    const [loading, setLoading] = useState(false)
    const [topComments, setTopComments] = useState([])
    useEffect(

        () => {

            const TopComments = async () => {


                try {

                    // Retrieve 'country' from local storage
                    const storedCountry = localStorage.getItem('country');

                    // Set a default value if 'country' is not present
                    const country = storedCountry !== null ? storedCountry : 'global';

                    // Encode the 'country' value to ensure it's URL-safe
                    const encodedCountry = encodeURIComponent(country);

                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/topComments/topCommentsData?country=${encodedCountry}`)



                    setTopComments(response.data)


                    setLoading(false)

                }
                catch (err) {

                    setLoading(false)
                }
            }

            TopComments()

        }, [])

    const decodeCommentText = (comment) => {

        const decodedComment = decodeURIComponent(comment);
        return decodedComment;
    };

    return (
        <Fragment>

            <div className='top-ten-post-title'>Top Comments</div>

            {
                loading ? 
                
                <div id="comment-loading">
                <Comment
                visible={true}
                height="80"
                width="80"
                ariaLabel="comment-loading"
                wrapperStyle={{}}
                wrapperClass="comment-wrapper"
                color="#fff"
                backgroundColor=" #00b41e"
              />
              </div>

                :
                    topComments.map(topComment => (
                        <Link  key={nanoid()} to={`/postbyid?post_id=${topComment.post_id}`} className='top-comment'  >

                            <div className='username-profile-picture'>
                                {
                                    topComment.profile_picture_link === "1" ?
                                        <div className="profile-photo"><img src={`https://res.cloudinary.com/dxkicctcv/image/upload/v1685291624/ofx2j9gkzncnmlf9cs33.png`} alt="profile-img" /></div>
                                        :
                                        <div className="profile-photo"><img src={topComment.profile_picture_link} alt="profile-img" /></div>
                                }
                                <div className='username'>
                                    {topComment.username}
                                </div>
                            </div>

                            <div className='top-comment-text'>
                                {decodeCommentText(topComment.comment_text)}
                            </div>
                        </Link>

                    ))}
        </Fragment>
    )
}

export default TopComments