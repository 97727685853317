import React, { useContext, useEffect, useState } from 'react'
import { nanoid } from 'nanoid';
import { UserContext } from '../../App'
import { Rings } from 'react-loader-spinner'
import SignInStatus from '../account/SignInStatus';
import UpdateAirtime from './UpdateAirtime';
import axios from 'axios';



function GiftBox() {

    const [ip, setIp] = useState('')

    const user = useContext(UserContext)
    const [gift, setGift] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        const fetchData = async () => {
            try {
                const ip = localStorage.getItem('ip');


                // Set a default value if 'country' is not present

                const encodedIp = encodeURIComponent(ip);


                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/v1/gift`
                );

                setGift(response.data)
                setIp(encodedIp)
                setLoading(false)
            }
            catch (err) {

                setLoading(false)
            }
        }

        fetchData()

    }, [])
    

    return (
        <div className='gift-box-container'>

            {

                loading ?
                    <Rings
                        height="100"
                        width="100"
                        color="#4fa94d"
                        radius="6"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                    />
                    :



                    ip === '"41.80.117.162"' || ip==='"41.80.114.138"' || (user && user.email === "misheckgithongo@gmail.com") || (user && user.email === "elmarkomellow@gmail.com") || (user && user.email === "liamlee112021@gmail.com") || (user && user.email === "prettyshie03@gmail.com") ?

                        <h6 style={{ color: "white", fontSize: "1.5em" }}>Sorry, this is not eligible for anyone involved in marketing.</h6>
                        :
                        <table id="table">
                            <thead>
                                <tr>
                                    <th>Mobile Network</th>
                                    <th>Airtime Codes</th>
                                </tr>
                            </thead>


                            <tbody>



                                {

                                    loading ?

                                        <Rings
                                            height="100"
                                            width="100"
                                            color="#4fa94d"
                                            radius="6"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="rings-loading"
                                        />
                                        :

                                        gift.map(giftDetails=>(
                                        <tr key={nanoid()}>

                                            <td>{giftDetails.isp}</td>
                                            <td>{giftDetails.airtime_codes}</td>
                                        </tr>
                                         ) )
                                }


                                <tr key={nanoid()}>


                                    <td>Keep coming back for more airtime code updates!</td>


                                </tr>


                            </tbody>

                        </table>
            }

            {
                user ? "" : <SignInStatus />
            }

            {
                user && user.email === "warigiachegesa@gmail.com" || (user && user.email === "muchunganelius681@gmail.com") ? <UpdateAirtime /> : ""
            }
        </div>
    )
}

export default GiftBox
