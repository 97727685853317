
import React, {useState,useEffect,useRef, Fragment} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Rings } from  'react-loader-spinner'
import { nanoid } from 'nanoid';
import axios from "axios";

function EmailUsers(){

    const[users,setUsers]=useState([])
    const[loading,setLoading]=useState(true)
    const[page,setPage]=useState(1)
    const [hasMore, setHasMore] = useState(true)
    const[userTally,setUsersTally]=useState(0)
    const loadingRef = useRef(true);



    useEffect(() => {
        const fetchData = async () => {

    
          try {


            const users = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/statistics/users`
            );
  

            setUsers(users.data)
            
            setLoading(false)
            console.log(users)
            
  
              
            } catch (error) {

                setUsers([])
                setLoading(false)
            } 
            
        };
    
        fetchData();
      }, []);


      useEffect(() => {
        const fetchData = async () => {

    
          try {




            const usersTally = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/usercount/all`
            );


            

           
            setUsersTally(usersTally.data[0].usersCount)
            setLoading(false)
            
            
  
              
            } catch (error) {

                setUsersTally([])
                setLoading(false)
            } 
            
        };
    
        fetchData();
      }, []);





      const DateTime=(dateObj)=>{


        let date = new Date(dateObj);

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    

    return formattedTimestamp

      }

      console.log(userTally)
    return(
        <Fragment>
            

        <div className="admin-dashboard" id="total-views">


<div className="user-views-table" id="all-views-table">
    




<table id="table">
    <thead>
        <tr>
        <th>Id</th>
        <th>Joining Date</th>
        <th>names</th>
        <th>User Name</th>
        
        </tr>
    </thead>


    <tbody>
       
      { loading ? "Loading":
          
          users.map(user=>(
        <tr key={nanoid()}>
            
            <td>{user.email}</td>
            <td>{DateTime(user.joining_date)}</td>
            <td>{user.names}</td>
            <td>{user.username}</td>
            
            </tr>
        
       
    ) ) }
    </tbody>

</table>


</div>

<div className="users-total-number">

  <h2 style={{color:"white"}}>
  
{
loading ?

'loading...':

userTally-14}
  
  </h2>

</div>

        </div>

        
    </Fragment>
          


    )
}

export default EmailUsers
