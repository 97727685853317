import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'


function SearchInput(props) {

    const [searchUsername, setSearchUsername] = useState({ username: "" })
    const [searchResults, setSearchResults] = useState([])
    const [loading, setLoading] = useState(false)

   

    useEffect(() => {

        const fetchData = async () => {
            try {

                setLoading(true)

                
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/posts/search?userName=${searchUsername.username}`)

                console.log(response.data);

                setSearchResults(response.data)
                setLoading(false)

            }
            catch (err) {

                console.log(err)
                setLoading(false)
            }
        }

        if(searchUsername.username!=="")
        
        fetchData()
        

    }, [searchUsername.username])

    return (
        <div className='search-input'>
            <div className="close-modal" onClick={()=>props.setSearchModal(false)}>
                X
            </div>
            <input type='search' placeholder='search' onChange={e => setSearchUsername({ ...searchUsername, username: e.target.value })} />

            <div className='search-result'>

                {
                    loading ? <h1>Loading...</h1>
                        :
                        searchResults.length > 0  ?

                            searchResults.map(searchDetails => (
                                <Link to={`/account?ud=${searchDetails.id}`}  className='search-link' key={searchDetails.id} onClick={()=>props.setSearchModal(false)}>
                                    <div className='search-res-wrapper'>
                                    <div className='inner-search-res-wrapper'>
                                        <div className='profile-img'>
                                            <img src={searchDetails.profile_picture_link == 1 ? "https://res.cloudinary.com/dxkicctcv/image/upload/v1685291624/ofx2j9gkzncnmlf9cs33.png" : searchDetails.profile_picture_link} alt='profile-img' />

                                        </div>

                                        <div className='username'>
                                            <h4>{searchDetails.username}</h4>
                                        </div>
                                    </div>
                                    </div>
                                </Link>
                            ))
                            :
                            "No results"
                }
            </div>

        </div>


    )
}

export default SearchInput