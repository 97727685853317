import React,{useState} from 'react'
import {BsSearch} from 'react-icons/bs'
import logo from '../images/happytimes.png'
import SearchInput from './search/SearchInput'

function Header(){

   const[searchModal,setSearchModal]=useState(false)
    return(
 <div className="header-details">

    {/* <div className="left-light"></div>
    
    <div className="right-light"></div> */}

 <div className="logo" id='logo'>

<img src={logo} alt='logo' />
    <h2>HappyTimesMemes</h2>

 </div>

 <div className="search" onClick={()=>setSearchModal(true)}>

        <BsSearch size="1.1em" color="#FFFDD0"/>

 </div>

 {searchModal && <SearchInput setSearchModal={setSearchModal} />}



 </div>

    )
}

export default Header