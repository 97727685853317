import React from "react";
import HeaderNavigationRoutes from "./HeaderNavigationRoutes";
import Post from "./Post";

function Home(){
    return(

    <>
    <HeaderNavigationRoutes />

    <Post/>

    
    
    </>
    )
}

export default Home