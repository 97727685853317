import React, { useState, useEffect } from 'react';
import axios from 'axios';

function TestL() {
  // const [country, setCountry] = useState('');

  // useEffect(() => {
  //   // Fetch user's IP address
  //   axios.get('https://api64.ipify.org?format=json').then(response => {
  //     const userIP = response.data.ip;

  //     // Fetch geolocation data using IP
  //     axios.get(`https://ipapi.co/${userIP}/json/`).then(response => {
  //       const userCountry = response.data.country_name;
  //       setCountry(userCountry);
  //     });
  //   });
  // }, []);

  return (
    // <div className="App">
    //   {/* <h1>Your Country: {country}</h1> */}
    // </div>

    <div className="vintage-tv">
      <div className="tv-screen">
        {/* Content or video component goes here */}
      </div>
      <div className="tv-body">
        <div className="tv-stand"></div>
        <div className="tv-knobs">
          <div className="knob knob-1"></div>
          <div className="knob knob-2"></div>
        </div>
      </div>
    </div>
  );
}

export default TestL;
