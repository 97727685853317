import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../App'
import Login from '../Login'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { AiOutlineProfile, AiFillHeart, AiOutlineFileAdd } from 'react-icons/ai'
import { VscThreeBars } from 'react-icons/vsc'
import axios from 'axios'
import AccountPosts from './posts/AccountPosts'
import { useLocation } from 'react-router-dom';
import ReactionsCount from './account/ReactionsCount'
import SignInStatus from './account/SignInStatus'


function Account() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ud = searchParams.get('ud');


    const user = useContext(UserContext)
    const [file, setFile] = useState(null)
    const [preview, setPreview] = useState(null)
    const [sidebar, setSideBar] = useState(false)
    const [editAccount, setEditAccount] = useState(false)
    const [username, setUsername] = useState({ userName: "", email: "" })
    const [loading, setLoading] = useState(true)
    const [profileLloading, setProfileLoading] = useState(true)
    const [postsDetails, setPostsDetails] = useState({ userId: ud ? ud : user ? user.email : "" })
    const [accountDetails, setAccountDetails] = useState([])
    const [usernameLoading, setUsernameLoading] = useState(false)




    useEffect(() => {

        const fetchAccountDetails = async () => {

            try {

                if (ud) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/viewaccount/${ud}`)

                    setAccountDetails(response.data)
                    
                    setUsername({ userName: response.data[0].username, email: ud })


                    setLoading(false)
                }
                else if (!ud) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/account?user=${user.email}`)

                    setAccountDetails(response.data)
                    
                    setUsername({ userName: response.data[0].username, email: user && user.email })
                    

                    setLoading(false)
                }


            }
            catch (err) {

                setLoading(false)
            }

        }

        fetchAccountDetails()

    }, [])

   



    const logOut = () => {
        window.open(
            `${process.env.REACT_APP_API_URL}/auth/logout`,
            "_self"
        )
    }


    const handleFileChange = event => {
        const selectedFile = event.target.files[0];
        setProfileLoading(true)

        const reader = new FileReader();
        reader.onload = () => {
            setPreview(reader.result);

            const isImage = selectedFile.type.startsWith('image/');
            const api = `${process.env.REACT_APP_API_URL}/api/v1/upload/updateProfile`;

            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append("postsDetails", JSON.stringify(postsDetails));

            const headers = {
                'Content-Type': selectedFile.type
            };

            axios.post(api, formData, { headers })
                .then(response => {
                    
                    setProfileLoading(false);
                })
                .catch(error => {
                    
                    setProfileLoading(false);
                });
        };

        if (selectedFile && selectedFile.type.startsWith('image/')) {
            reader.readAsDataURL(selectedFile);
        }
    };


    const onSubmitHandler = async (e) => {

        e.preventDefault()

        try {

            setUsernameLoading(true)

            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/details/updateUser`, username);

            setUsernameLoading(false)

        }

        catch (error) {

            console.log(error)
        }
    }

    

    return (

        <>

            {
                user || ud ?


                    <div className="profile-pages">

                        <div className="user-personal-details">
                            {
                                loading ? <p id="profile-loading">Loading..</p>
                                    :
                                    accountDetails.map(userDetails => (
                                        < div className='pic-username'>

                                            {
                                                userDetails.profile_picture_link === "1" ?
                                                    <div key={userDetails.username} className="user-profile-img"><img src={`https://res.cloudinary.com/dxkicctcv/image/upload/v1685291624/ofx2j9gkzncnmlf9cs33.png`} alt="profile" /> </div>

                                                    :

                                                    <div key={userDetails.username} className="user-profile-img"><img src={userDetails.profile_picture_link} alt="profile" /> </div>
                                            }

                                            <div key={userDetails.profile_picture_link} id='account-username'>

                                                <h4>{userDetails.username}</h4>

                                            </div>

                                        </div>
                                    ))
                            }

                            {

                                ud ? "" : <div className="edit-account"><button onClick={() => setEditAccount(true)}>Edit Account</button></div>
                            }

                            {
                                editAccount &&
                                <div className='edit-account-details-wrapper'>

                                    <div className='edit-account-details'>
                                        <div className='edit-account-modal' onClick={() => setEditAccount(false)}>
                                            X
                                        </div>

                                        <div className='edit-acc'>

                                            <input type="file" id="image" accept="image/*" className='profile-label' onChange={handleFileChange} required />

                                            <label htmlFor="image">
                                                <div className="user-profile-img" id='edit-ac-profile'>

                                                    <AiOutlineFileAdd size='1.5em' color='#00b41e' className='acc-edit-icon' />
                                                    {
                                                        preview && loading ?

                                                            <>
                                                                <img src={preview} alt="profile" />
                                                                <p id="profile-upload">Uploading..</p>
                                                            </>
                                                            :
                                                            preview ?
                                                                <img src={preview} alt="profile" />


                                                                :


                                                                <img src={accountDetails[0].profile_picture_link} alt="profile" />

                                                    }
                                                    <h4>Update Profile</h4>

                                                </div>
                                            </label>

                                            <form className='update-username' onSubmit={onSubmitHandler}>
                                                <label>Update Username</label>
                                                <input type='text' value={username.userName} onChange={e => setUsername({ ...username, userName: e.target.value })} required />

                                                <button type='submit' disabled={usernameLoading}>{usernameLoading ? 'loading...' : 'Update'}</button>
                                            </form>

                                        </div>

                                    </div>
                                </div>
                            }

                            {

                            loading  ? 'Loading..':<ReactionsCount  accountDetails={accountDetails}/>
}


                            {

                                ud ? "" : <VscThreeBars size='2em' className='accounts-kebab' onClick={() => setSideBar(true)} />
                            }

                        </div>



                        <div className="user-profile-nav">
                            <div className="account-links"><AiOutlineProfile size="1.4em" />
                                <div>
                                    {
                                        ud ?
                                            "Posts"
                                            :
                                            "Your Posts"
                                    }
                                </div>
                            </div>
                            <AccountLinks to="user-liked"><div className="account-links"><AiFillHeart /><div>Liked</div></div></AccountLinks>
                        </div>

                        <AccountPosts email={ud ? ud : ud && user.email ? ud : !ud && user ? user.email : ""} />

                        {
                            sidebar &&
                            <div className='accounts-sidebar-wrapper'>
                                <div className='accounts-sidebar'>
                                    <div className='accounts-sidebar-close' onClick={() => setSideBar(false)}>
                                        X
                                    </div>

                                    <div className='sign-out-details'>
                                        <button onClick={logOut} className="sign-out">Sign Out</button>
                                    </div>


                                </div>
                            </div>
                        }
                    </div>

                    : <SignInStatus />

            }

        </>


    )
}

export default Account

function AccountLinks({ to, children, ...props }) {

    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <Link to={to} {...props}>{children}</Link>
    )

}

