import React, { useState,useContext } from 'react';
import { UserContext } from '../App';
import axios from 'axios';
import {BiImage} from 'react-icons/bi'
import {FaVideo} from 'react-icons/fa'
import { Rings } from  'react-loader-spinner'


function UploadVideoImage({closePostUploadModal}){


  const user=useContext(UserContext)
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const[success,setSuccess]=useState(false);
  const [loading, setLoading] = useState(false);
  const[postsDetails,setPostsDetails]=useState({caption:"1",audience:"", userId:user && user.email})
  const[audience,setAudience]=useState('')
  const[err,setErr]=useState(false)
  
  
  const handleFileChange = event => {
    setFile(event.target.files[0]);
    setSuccess(false)

    const reader = new FileReader();

    if (event.target.files[0].type.startsWith('image/')) {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setPreview(reader.result);
      };
    } else if (event.target.files[0].type.startsWith('video/')) {
      setPreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleUpload = () => {
    if (!file) return;

    if(postsDetails.audience==='' ) {setErr(true)}
    
    else
{
    setLoading(true);
console.log("uploading")
    const isImage = file.type.startsWith('image/');
    const api = isImage ? `${process.env.REACT_APP_API_URL}/api/v1/upload/image` : `${process.env.REACT_APP_API_URL}/api/v1/upload/video`;

    const formData = new FormData();
    formData.append('file', file);
    formData.append("postsDetails", JSON.stringify(postsDetails));

    const headers = {
      'Content-Type': file.type
      
    };

    axios.post(api, formData, { headers })
      .then(response => {
        
        setPreview(null)
        setFile(null)
        setSuccess(true)
        setLoading(false);
      })
      .catch(error => {
        
        setLoading(false);
      });
    }
  };

  const handleCaption=(event)=>{
    setPostsDetails({...postsDetails,
      caption:encodeURIComponent(event.target.value),
      userId:user.email
    })

    
  }  

  

  function handleRadioChange(event) {

    setPostsDetails({...postsDetails,audience:event.target.value})
    
}

  console.log(err)
  console.log(postsDetails)
  return (
    <div className="uploadPostContainer">


     {success && <div className='success'>
      Post Uploaded Successfully. Refresh your feed or visit your account to view.

      </div>

  }
      
      <div onClick={()=>closePostUploadModal(false)} className="close-post-upload">X</div>

      <div className="upload-post-wrapper">

<div className="upload-files-div">

        <input type="file" id="file" accept="video/*" onChange={handleFileChange} required/>


<label htmlFor="file">
<div>
<FaVideo color="white" fontWeight="bold" size="2em"/></div> <div style={{color:"white"}}>Chose Video</div>

</label>

      <input type="file" id="image" accept="image/*"  onChange={handleFileChange} required/>

<label htmlFor="image">
<div>
      <BiImage color="white" fontWeight="bold" size="2em"/></div> <div style={{color:"white"}}>Chose Photo</div>

      </label>
</div>

       

      <div className="post-upload-caption">
        
        <div className="title">
            <h3>Caption</h3>
        </div>

          <div className="upload-textarea">

            
          <textarea rows="4" cols="10" placeholder="Caption......" onChange={handleCaption}>

            </textarea>

          </div>

      </div>

      <div id="title">
            <h3>Content Suitable For:<i>Check meme language/context</i></h3>
        </div>

<div className='define-audience'>
      <label>
        <input type="radio" name="option" value="0" onChange={handleRadioChange}/>
        My Country Only
    </label>

    <label>
        <input type="radio" name="option" value="1" onChange={handleRadioChange}/>
     Global Audience
    </label>

    {err && <div className='audience-err' onClick={()=>setErr(false)}>

      <div  className='closeButton'>
x

      </div>
      
      Please Select audience type</div>}
    </div>

      {preview && file.type.startsWith('image/') && (

        <div className="uploaded-file">
        <img src={preview} alt="Preview" />
        </div>
      )}
      {preview && file.type.startsWith('video/') && (
        <div className="uploaded-file">
        <video autoPlay loop  muted playsInline controls src={preview} />
        </div>
      )}

        {
          loading?
          
          <Rings
          height="100"
          width="100"
          color="#4fa94d"
          radius="6"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"
        />
        :
      <button className="file-upload-btn" onClick={handleUpload} disabled={loading}>
        Post
      </button>
}
      </div>
    </div>
  );
};

export default UploadVideoImage;
