import React, { useState } from 'react';
import img1 from '../images/profile-min.JPG'
import { GoKebabVertical } from 'react-icons/go'
import { Link } from "react-router-dom";
import Tv from './Tv';
import { useEffect } from 'react';
import axios from 'axios'
import 'react-html5video/dist/styles.css'
import { DefaultPlayer as Video } from 'react-html5video'
import TopComments from './comments/TopComments'
import { Rings } from  'react-loader-spinner'


function WallOfFame() {

  const [post, setPost] = useState([])
  const [loading, setLoading] = useState(true)
  const [topTenPostPreview, setTopTenPostPreview] = useState([])
  const [isMuted, setIsMuted] = useState(true);

  const handleVideoPlay = () => {
    setIsMuted(false);
  };

  useEffect(() => {
    const fetchData = async () => {

      // Retrieve 'country' from local storage
      const storedCountry = localStorage.getItem('country');

      // Set a default value if 'country' is not present
      const country = storedCountry !== null ? storedCountry : 'global';

      // Encode the 'country' value to ensure it's URL-safe
      const encodedCountry = encodeURIComponent(country);
      

      try {
        const topPostResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/posts/topPost?country={"country":${encodedCountry}}`
        );

        const topTenPostPreviewResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/posts/topTenPostPreview?country={"country":${encodedCountry}}`
        );

        setPost(topPostResponse.data);
        setTopTenPostPreview(topTenPostPreviewResponse.data);
        setLoading(false);

      } catch (error) {
        setPost({});
        setTopTenPostPreview({});
      }
    };

    fetchData();
  }, []);



  // const cloudinaryUrl = post.file_link;
  // const autoOptimizedUrl = cloudinaryUrl.replace('/upload/', '/upload/q_auto/');
  return (

    <div className='wall-of-fame'>


      <h2 className="wallOfFameHeading">Top Daily Dose</h2>

      <Link to="/toppost" >


        <div className='wall-of-fame-screen'>

          {
            loading ? 
            
            <div className='post-loading'>
            <Rings
    height="100"
    width="100"
    color="#4fa94d"
    radius="6"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel="rings-loading"
  /> 
  </div>
  
  :

              post.map(post_details => (
                post_details.file_type === "video" ?
                  <Video key={post.post_id} playsInline autoPlay muted loop >

                    <source src={post_details.file_link} type="video/mp4" />


                  </Video>
                  :
                  post_details.file_type === "image" ?
                    <img src={post_details.file_link} alt="post" />
                    :
                    <p style={{ color: 'white' }}>{post_details.file_type}</p>

              ))

          }
        </div>



      </Link>





      {/* <Tv /> */}

      <div className="top-posts-comments-nav">

        <div className="links">
          <Link to="/toptenposts" className='Links'>
            <div className='top-ten-post-title'>Top 10 Daily Dose</div>

            <div className='top-ten-post-previews'>

              {
                loading ? 
                <div className='post-loading'>
                <Rings
        height="100"
        width="100"
        color="#4fa94d"
        radius="6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading"
      /> 
      </div>
                
                :
                  topTenPostPreview.map(post => (
                    post.file_type === "video" ?

                      <Video key={post.post_id} playsInline  >

                        <source src={post.file_link} type="video/mp4" />


                      </Video>

                      :
                      post.file_type === "image" ?
                        <img key={post.post_id} src={post.file_link} alt="post" />

                        :
                        <p style={{ color: 'white' }}>{post.file_type}</p>

                  ))}
            </div>
          </Link>
        </div>


        <div className="links">
          <TopComments />

        </div>


        {/* <div className="links">
          <Link to="/topcomment" className='Links' >
            <div className='top-ten-post-title'>Top Global</div>
            <div className='top-global'>

            </div>
          </Link>
        </div> */}


      </div>

      {/* <h4  id="top-comment-heading">Top Comments</h4>
            

 */}

<div className='height'>

</div>

    </div>
  )
}

export default WallOfFame