import React, {useState,useEffect} from "react";
import { nanoid } from 'nanoid';
import axios from "axios";

function Allanoadminhappy(){

    const[userActivity,setUserActivity]=useState([])
    const[views,setViews]=useState([])
    const[totalViews,setTotalViews]=useState([])
    const[loading,setLoading]=useState(false)



    useEffect(() => {
        const fetchData = async () => {

    
          try {
            const totalViewsResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/statistics/totalViews`
            );
    
            const userActivityResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/statistics/userActivity`
            );


            
    
            setViews(totalViewsResponse.data);
            setUserActivity(userActivityResponse.data);
           
            setLoading(false);
    
          } catch (error) {
            setUserActivity([]);
            setViews([]);
          }
        };
    
        fetchData();
      }, []);


      const decodeUserIp = (userIp) => {
    
        const UserIp = decodeURIComponent(userIp);
       
        return UserIp;
      };

      const decodeUserId = (userId) => {
    
        const UserId = decodeURIComponent(userId);
       
        return UserId;
      };


    return(
        <div className="admin-dashboard">

            <div className="user-views-table">

            <table>
                <thead>
                    <tr>
                    <th>Total Views</th>
                    <th>Number Of Visitors</th>
                    <th>Registered Members</th>
                    </tr>
                </thead>


                <tbody>
                   
                  { loading ? "Loading":
                      
                      views.map(viewDetails=>(
                    <tr key={nanoid()}>
                        
                        <td>{viewDetails.totalViews}</td>
                        <td>{viewDetails.NumberOfVisitors}</td>
                        <td>10,016</td>
                        </tr>
                    
                   
                ) ) }
                </tbody>

            </table>
            </div>


            <div className="user-activity-table">

<table>
    <thead>
        <tr>
        <th>User Ip</th>
        <th>User Id / Country</th>
        <th>User Total Views</th>
        </tr>
    </thead>


    <tbody>
       
            {
                loading ? "Loading":
                userActivity.map(userDetails=>(
        <tr key={nanoid()}>
            
            <td>{ decodeUserIp(userDetails.userIp)}</td>
            <td>{decodeUserId(userDetails.user_id)}</td>
            <td>{userDetails.userViews}</td>
            </tr>
      ) ) }
       

    </tbody>

</table>
</div>




        </div>


    )
}

export default Allanoadminhappy