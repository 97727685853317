
import React, {useState,useEffect,useRef, Fragment} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Rings } from  'react-loader-spinner'
import { nanoid } from 'nanoid';
import axios from "axios";

function TotalViews(){

    const[totalViews,setTotalViews]=useState([])
    const[loading,setLoading]=useState(false)
    const[page,setPage]=useState(1)
    const [hasMore, setHasMore] = useState(true)
    const loadingRef = useRef(true);



    useEffect(() => {
        const fetchData = async () => {

    
          try {


            const TotalViews = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/statistics/allViews?Details={"page":${page}}`
            );


            

            setTotalViews(prevTotalViews => [...prevTotalViews, ...TotalViews.data]);
            
            
            if (TotalViews.data.length < 40) {
                setHasMore(false);
              }
              
            } catch (error) {

                setTotalViews([])
            } finally {
              loadingRef.current = false
            }
            
        };
    
        fetchData();
      }, [page]);

      const fetchMoreData = () => {
        setPage(prevPage => prevPage + 1)
      };



      const DateTime=(dateObj)=>{


        let date = new Date(dateObj);

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedTimestamp

      }


    return(
        <Fragment>
            

        <div className="admin-dashboard" id="total-views">


<div className="user-views-table" id="all-views-table">
    


<InfiniteScroll
        dataLength={totalViews.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={ 

          <div className='post-loading'>
          <Rings
  height="100"
  width="100"
  color="#4fa94d"
  radius="6"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel="rings-loading"
/> 
</div>

        }
      >

<table id="table">
    <thead>
        <tr>
        <th>Id</th>
        <th>Post Id</th>
        <th>Email/Country</th>
        <th>User Ip</th>
        <th>Date Time</th>
        </tr>
    </thead>


    <tbody>
       
      { loadingRef.current ? "Loading":
          
          totalViews.map(views=>(
        <tr key={nanoid()}>
            
            <td>{views.post_views_id}</td>
            <td>{views.post_id}</td>
            <td>{decodeURIComponent(views.user_id)}</td>
            <td>{decodeURIComponent(views.userIp)}</td>
            <td>{DateTime(views.date_time)}</td>
            
            </tr>
        
       
    ) ) }
    </tbody>

</table>
</InfiniteScroll>

</div>

        </div>

        
    </Fragment>
          


    )
}

export default TotalViews
