import React,{useState,useContext,useEffect} from "react";
import {SlUserFollow} from 'react-icons/sl'
import {RiUserUnfollowLine} from 'react-icons/ri'
import {MdReport} from 'react-icons/md'
import {UserContext} from '../../App'
import axios from "axios";

function FollowModal(props){
    const user = useContext(UserContext)
    const[followAction,setFollowAction]=useState({follower_email:user.email,followed_email:props.email})
    const[followStatus,setFollowStatus]=useState(false)
    const[loading,setLoading]=useState(true)
    

    useEffect(() => {

        const fetchFollowStatus = async () => {
            setLoading(true)
            try {

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/follow/followStatus?emails={"followed_email":"${followAction.followed_email}","follower_email":"${followAction.follower_email}"}`)

                if(response.data.length===0){
                setFollowStatus(false)
                }
                else{
                    setFollowStatus(true)
                }
               

                setLoading(false)

            }
            catch (err) {

                setLoading(false)
            }

        }

        fetchFollowStatus()

    }, [])

    const HandleFollow=async ()=>{


       
            
            try {

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/follow/followActivity?emails={"followed_email":"${props.email}","follower_email":"${user.email}"}`)

                
                setFollowStatus((prevState)=>!prevState)
                


                setLoading(false)

            }
            catch (err) {

                setLoading(false)
            }



    }

    return(

        <div className="follow-modal">
            <div className="close-modal" onClick={()=>props.closeFollowModal(false)}>
                X
            </div>

            <div className="follow-menu">

          
                <>
                {
                followStatus?
           
            <button onClick={()=>HandleFollow()}><RiUserUnfollowLine className="follow-icon"/>Unfollow</button>
            :
            <button onClick={()=>HandleFollow()}><SlUserFollow className="follow-icon"/>Follow</button>
        }
            </>

            <button><MdReport className="follow-icon"/>Report</button>
            </div>

        </div>
    )
}

export default FollowModal