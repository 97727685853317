import React from "react";
import 'react-html5video/dist/styles.css'
import { DefaultPlayer as Video } from 'react-html5video'

function FileDisplay(props){

    return(
        <>
                       { 
                       props.file_details.length>0?
                       
                       props.file_details[0].file_type==="video" ? 
                        <Video  playsInline  >

                            <source src={props.file_details[0].file_link} type="video/mp4"/>
                           

                        </Video>
                        :
                        props.file_details[0].file_type==="image"?
                         <img src={props.file_details[0].file_link} alt="post" /> 
                         :
                         <p>.</p>
                         :
                         <p>No Posts</p>
                        } 
        </>
    )
}

export default FileDisplay