import React,{useState,useEffect} from "react"
import axios from 'axios'
import Tv from "../Tv"
import ProfileDetails from "../ProfileDetails"


function Toptenposts(){

    const[posts,setPost]=useState([])
    const[loading,setLoading]=useState(true)

    useEffect(() => {
        const fetchData = async () => {

                          // Retrieve 'country' from local storage
                          const storedCountry = localStorage.getItem('country');
      
                          // Set a default value if 'country' is not present
                          const country = storedCountry !== null ? storedCountry : 'global';
                      
                          // Encode the 'country' value to ensure it's URL-safe
                          const encodedCountry = encodeURIComponent(country);

          try {
            const Response = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/posts/topTenPosts?country={"country":${encodedCountry}}`
            )
    
            setPost(Response.data)
           
            setLoading(false)
    

          } catch (error) {
            setPost({})
            setLoading(false)
          }
        };
    
        fetchData()
      }, [])

    return(
        <div >
            <div className='top-ten-post-title' id="top-ten-component">
              <h3>Top 10 Daily Dose</h3>  
                </div>


                {
                    loading ? 'Loading....' :
                    posts.map(post=>(
                        <div  className="post-parent" key={post.post_id}>

<ProfileDetails profile_picture_link={post.profile_picture_link} userName={post.username} date_time={post.date_time} email={post.email}  />

                        <Tv file_link={post.file_link} file_type={post.file_type} post_id={post.post_id} numberOfVotes={post.numberOfVotes} views={post.view_count}/>

                        </div>
                    ))
                }

        </div>
    )
}

export default Toptenposts