import React,{useState,useEffect} from 'react'
import { FcGoogle } from 'react-icons/fc'
import fbimg from '../../images/fbsignin.jpeg'
import axios from 'axios'




function SignInStatus(){

    const[status,setStatus]=useState(false)
    const[userEmail,setEmail]=useState({email:"",confirmEmail:"",loading:false})
    const[res,setRes]=useState([])
    const[errorModal,setErrorModal]=useState(false)
    const[loading,setLoading]=useState(false)

    useEffect(()=>{
      const isUnsupportedBrowser = () => {

        setLoading(true)
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        setLoading(false)
        return /FBAN|FBAV|Instagram/.test(userAgent);
        
      };
      
    
    // Example usage
    if (isUnsupportedBrowser()) {
                // Example usage
setStatus(true)
setLoading(false)
    
    //     alert("For the best experience, we recommend using Chrome, Firefox, or Opera Mini.");
    }
  
    },[])

    const googleAuth = () => {


        if(!status && !loading){
           
        window.location.href = `${process.env.REACT_APP_API_URL}/auth/google/callback`;
        }
    }

    const HandleSubmit = async (e) => {
      e.preventDefault();
      setEmail({ loading: true });
  
      const email = {
        email: userEmail.email
       
        
      };
if(userEmail.email!==userEmail.confirmEmail){

  setRes({msg:"emails not matching"})
  setEmail({email:userEmail.email,confirmEmail:userEmail.confirmEmail})
  setErrorModal(true)
  
}
else{
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/fbReg`,
          email
        );
        setRes(response.data)
        setErrorModal(true)
        
        setEmail({ loading: false, email: "" , confirmEmail: "" }); // Reset the comment input after successful submission
      //   props.setCommentSent(prev => !prev); // Toggle commentSent to trigger re-fetching comments
      } catch (error) {
        setEmail({ loading: false, email: "" , confirmEmail: "" });
      }
}
    };

    
    return(

        <div className="sign-in-status" style={{color:"white"}}>

<div className='sign-in-page'>

<h1 className='action'>



Create an account or sign in to continue for:

<p>😂 Endless Laughter</p>
<p>🎁 Daily Safaricom,Airtel,Telkom Airtime Gifts in Gift Box</p>
<p>🌍 Local and Global Memer Community</p>
<p>📷 Instant Meme Sharing</p>




</h1>
{
  status ?
  <form onSubmit={HandleSubmit}>
  <div className='sign-in-div'>
  <label>Enter Your Email</label>
  <input type="email" onChange={e => setEmail({ ...userEmail, email: e.target.value })} required />
  </div>

  <div className='sign-in-div'>
  <label>Confirm Your Email</label>
  <input type="email" onChange={e => setEmail({ ...userEmail, confirmEmail: e.target.value })} required/>
  </div>

  <div id='sign-in-div'>
  <button type="submit" disabled={userEmail.loading} >
    {

      userEmail.loading? "Please Wait.."
      :
      "Register"
    }
    
     </button>
  </div>

</form>
      
:
<button onClick={googleAuth}><FcGoogle size='1.5em' className='google-icon'/> 
{
  loading?"Please Wait":
"Continue With Google"
}
</button>
}
  </div>

  {/* {status && <div className='fb'>

<div className='fb-wrapper'>
<p>
Unlock all features and get daily free airtime! Sign up now with Google Chrome, Opera Mini, or Firefox for the best experience.
</p>
<p>
Step 1: Click the three dots at the top-right of your browser.
</p>
<p>
Step 2: Select 'Open in External Browser.'
</p>

<p>
Step 3: Choose your preferred browser.
</p>

<div className='sign-in-img'>
<img src={fbimg} alt='Sign Up Instructions' />
</div>

</div>
</div>} */}

{
  errorModal && <div className='errorModal' onClick={()=>setErrorModal(false)}>
    <div className='closeButton' >X</div>

    {
      res.msg==="Email record inserted successfully" ?
      <h3>Registered Successfully. A verification link has been sent to your email. Please open your email and verify your email.</h3>
      :
      res.msg==="Email already exists" ?
      <h3>Email exists.A verification link has been sent to your email. Please open your email and verify your email</h3>:
      res.msg==="emails not matching" ? <h3>Sorry, emails do not match.</h3>:
      ""
      
    }
    
     </div>
}

  </div>

    )
}

export default SignInStatus