import React from 'react'
import { Link } from 'react-router-dom'

function TopComment(){
    return(
        <>
        <div className="top-comment">
        <Link to="topComment">
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        </p>

        </Link>
        </div>

        <div className="top-comment">
        <Link to="topComment">
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        </p>

        </Link>
        </div>

        </>
    )
}

export default TopComment