import React, { Fragment } from "react";
import { useState,useContext } from "react";
import {FaRegCommentAlt} from 'react-icons/fa'
import Comments from './Comments'
import {UserContext} from '../App'
import { Link } from "react-router-dom";

function CommentModalController(props){

    const user=useContext(UserContext)

    const[Commentsmodal,setOpenCommentsModal]=useState(false)
    
    return(
        <Fragment>
        { 
        user?

        // <div className="comments" onClick={()=>{setOpenCommentsModal(true)}} ><FaRegCommentAlt /><div className="numbers">1008</div>  </div>
        <div className="rxns" onClick={()=>{setOpenCommentsModal(true)}}  ><FaRegCommentAlt /><div className="count">{props.numberOfComments}
        
        </div> 
         </div>

            :
            <Link to="/account">

        {/* <div className="comments" onClick={()=>{setOpenCommentsModal(true)}} ><FaRegCommentAlt /><div className="numbers">1008</div>  </div> */}

        <div className="rxns" onClick={()=>{setOpenCommentsModal(true)}}  ><FaRegCommentAlt /><div className="count">{props.numberOfComments}</div>  </div>

            </Link>

        }
        {Commentsmodal && <Comments post_id={props.post_id} closeCommentsModal={setOpenCommentsModal} /> } 
        </Fragment>
    )
}

export default CommentModalController