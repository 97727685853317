import React from 'react'
import { Link,useMatch,useResolvedPath } from 'react-router-dom'


function HeaderNavigation(){

    //header navogation helps to filter posts by region or followers

    return(
        <div className="header-navigation">
            <HeaderLinks to="/"><div className="post-filter-links">Local</div></HeaderLinks>

            <HeaderLinks to="/foryou"><div className="post-filter-links">Local(Random)</div></HeaderLinks>

            <HeaderLinks to="/global"><div className="post-filter-links">Global</div></HeaderLinks>


        </div>
    )
}

export default HeaderNavigation

function HeaderLinks({to,children,...props}){

    const resolvedPath=useResolvedPath(to)
    const isActive=useMatch({path: resolvedPath.pathname,end:true})
    return(
        <Link to={to} {...props} className={isActive ? 'active' : "notActive"}>{children}</Link>
    )

}