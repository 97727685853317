import React from "react";
import {Routes,Route} from "react-router-dom"
import HeaderNavigation from "./HeaderNavigation";
import LocalContent from "./LocalContent"
import Home from "./Home";
import ForYou from "./posts/ForYou";


function HeaderNavigationRoutes(){

    return(

        <>
            <HeaderNavigation />


        </>
    )
}

export default HeaderNavigationRoutes