import React,{useState} from "react";
import logo from './images/happytimes.png'
import HomePageMeme from './images/HappyTimesHome.jpeg'
import {FiCopy} from 'react-icons/fi'

function HomePage(props) {

    const [link, setLink] = useState('');
    

    const generateLink = () => {
      const baseUrl = window.location.origin; // Get the base URL of the current page
      const generatedLink = `${baseUrl}`;
      setLink(generatedLink);
    }
  
    const copyLink = () => {
      const textArea = document.createElement('textarea');
      textArea.value = link;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setLink('')
    }

    const updateVisitCount = () => {
        // Check if local storage is supported
        if (typeof localStorage !== 'undefined') {
          // Retrieve the current value from local storage
          const currentCount = localStorage.getItem('visitCount');
      
          if (currentCount) {
            // If value exists, increment and update
            const updatedCount = parseInt(currentCount) + 1;
            localStorage.setItem('visitCount', updatedCount.toString());
          } else {
            // If value doesn't exist, set to 1
            localStorage.setItem('visitCount', '1');
          }
        }
      };
      

      

    return (
        <div className="HomePage">

            <div className="name-logo">

                <div className="logo">
                    <img src={logo} alt="logo" />
                </div>
                
                <h2>HappyTimes Memes</h2>

            </div>

            <div className="container">



                <div className="intro-meme">
                <img src={HomePageMeme} alt="logo" /> 
                </div>

                <div className="intro-text">
                   
Introducing a new social media platform that spreads happiness through memes and funny posts shared by users.We shares ad revenue with members when profitable, rather than keeping it all like other social media. Join the revolution.Post 5 daily memes (Daily Dose); top reactions hit Wall of Fame. Join MemeBattles like hashtags
                </div>

                <button className="home-page-btn" onClick={()=>{props.setStatus(true); updateVisitCount()}}>Continue Enjoying</button>
                <button className="home-page-btn" onClick={generateLink}>Share With Friends</button>

                {link && (
        <div className='copy-div' id="copy-text">
          <input type="text" value={link} readOnly />
          <button onClick={copyLink} id="copy-btn"><FiCopy size="1em" />Copy Link</button>
        </div>
      )}




            </div>
        </div>
    )
}

export default HomePage