import React from "react";
import HeaderNavigationRoutes from "../HeaderNavigationRoutes";
import RecentPosts from "../RecentPosts";

function ForYou(){

    return(

        <div>
            <HeaderNavigationRoutes />

            <RecentPosts />
        </div>
    )
}

export default ForYou