import React, { Fragment } from 'react'
import {AiOutlineEye,AiOutlineRetweet} from 'react-icons/ai'
import {BiShareAlt} from 'react-icons/bi'
import { IconContext } from 'react-icons'
import {BsPlusLg} from 'react-icons/bs'
import Tv from './Tv'
import {useState,useRef,useEffect,useContext} from 'react'
import axios from 'axios'
import ProfileDetails from './ProfileDetails'
import UploadVideoImage from './UploadVideoImage'
import { nanoid } from 'nanoid'
import InfiniteScroll from "react-infinite-scroll-component";
import VoteHandler from './VoteHandler'
import {UserContext} from '../App'
import CommentModalController from './CommentModalController'
import TvVersionOne from './TvVersionOne'
import { Rings } from  'react-loader-spinner'
import SignInStatus from './account/SignInStatus'


function VideosPosts(){

    const[PostUploadModal,setPostUploadModal]=useState(false)
    const[posts,setPosts]=useState([])
    // const[loading,setLoading]=useState(true)
    const[page,setPage]=useState(1)
    const [hasMore, setHasMore] = useState(true);
    const[error,setError]=useState('')
    const [showSignInStatus, setShowSignInStatus] = useState(false);

    const user=useContext(UserContext)
    const loadingRef = useRef(true);
    
    const decodeCaptionText = (caption) => {
    
      const decodedCaption = decodeURIComponent(caption);
     
      return decodedCaption;
    };
    //get all posts

    useEffect(()=>{

      const fetchData = async () => {
        
        try {
          // Retrieve 'country' from local storage
          const storedCountry = localStorage.getItem('country');
      
          // Set a default value if 'country' is not present
          const country = storedCountry !== null ? storedCountry : 'global';
      
          // Encode the 'country' value to ensure it's URL-safe
          const encodedCountry = encodeURIComponent(country);
      
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/posts/videoPosts?postDetails={"page":${page},"country":${encodedCountry}}`
          );

            setPosts((prevPosts) => {

              const newPosts = response.data.filter((post) => !prevPosts.some((p) => p.post_id === post.post_id));
              return [...prevPosts, ...newPosts];
            })
            if (response.data.length < 5) {
              setHasMore(false);
            }
            setError('')
          } catch (error) {
            setPosts({})
            setError('Something went wrong')
          } finally {
            loadingRef.current = false
          }
        };

      
        fetchData();
      
  },[page])


    const fetchMoreData = () => {
        setPage(prevPage => prevPage + 1)
      };

      useEffect(() => {
        if (!user) {
          const signInStatusTimeout = setTimeout(() => {
            setShowSignInStatus(true);
          }, 120000); // 3000 mins
    
          return () => clearTimeout(signInStatusTimeout); // Clear the timeout if component unmounts
        }
      }, [user]);

    // contains the post structure
    return(

        
        <Fragment>
            
            <InfiniteScroll
        dataLength={posts.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={

          <>
          <div className='post-loading' >
            <Rings
    height="100"
    width="100"
    color="#4fa94d"
    radius="6"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel="rings-loading"
  /> 
  </div>
  
  <div id='posts-loading'>
      
      <p>Loading More Posts...</p>
      <div id="loading-spinner"></div>
  </div>
  
  
  </>

        }
      >
        {loadingRef.current ? 
        
        <div className='post-loading'>
        <Rings
height="100"
width="100"
color="#4fa94d"
radius="6"
wrapperStyle={{}}
wrapperClass=""
visible={true}
ariaLabel="rings-loading"
/> 
</div>

        :
        
      posts.map(post=>(
            
        <div  className="post-parent" key={nanoid()}>
           
{/* contains profile contents that is the photo ,follow button and kebab menu */}

<ProfileDetails profile_picture_link={post.profile_picture_link} userName={post.username} date_time={post.date_time} post_id={post.post_id} email={post.email}/>

{/* contains the tv design structure. Post media will be displayed here */}
        {/* caption div */}
        
       <Tv file_link={post.file_link} file_type={post.file_type} post_id={post.post_id} page={page} numberOfVotes={post.numberOfVotes} numberOfComments={post.numberOfComments} views={post.view_count}/>

        {/* <TvVersionOne file_link={post.file_link} file_type={post.file_type} /> */}

{/* contains the like, view , comment , expose and share menu */}

        {/* <div className="post-reactions">
        <IconContext.Provider value={{size:"1.5em", color:"#FFFDD0" , padding:".5em"}} >
            <div className="view"><AiOutlineEye color="white"/><div className="numbers">10.7K</div> </div>

          <CommentModalController post_id={post.post_id} />
            

            <div className="expose"><AiOutlineRetweet /><div className="numbers">5000</div> </div>

            <VoteHandler email={post.email} post_id={post.post_id} page={page} numberOfVotes={post.numberOfVotes} />


            <div className="share"><BiShareAlt /></div>

            </IconContext.Provider >
        </div> */}
        

        {post.caption!=="1"?<div className="caption"><p>{decodeCaptionText(post.caption)}</p></div> :""}

      
       
        </div>
       

 ))} 
</InfiniteScroll>

        


{/* <div className="post-upload" onClick={()=>{setPostUploadModal(true)}}><BsPlusLg color="#FFF" size="1.5em" /></div>

{PostUploadModal && <UploadVideoImage closePostUploadModal={setPostUploadModal}/>} */}

{
  showSignInStatus && <SignInStatus />
} 

</Fragment>
        
    )

}

export default VideosPosts