import React,{useState,useEffect} from "react";
import axios from "axios";
import FileDisplay from "./FileDisplay";
import 'react-html5video/dist/styles.css'
import { DefaultPlayer as Video } from 'react-html5video'
import UserAccountPosts from "../UserAccountPosts";

function AccountPosts(props) {

//     const[posts,setPosts]=useState([])
//     const[loading,setLoading]=useState(true)

//     useEffect(() => {

//         const fetchAccountPosts = async () => {
//             setLoading(true)
//             try {

//                 const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/posts/accountPosts?email=${props.email}`)

//                 setPosts(response.data)
                
//                 console.log('account posts display',response.data)

//                 setLoading(false)

//             }
//             catch (err) {

//                 setLoading(false)
//             }

//         }

//         fetchAccountPosts()

//     }, [])
// console.log(posts.length)
    return (

        // <div className='account-posts'>
        <>
            <UserAccountPosts email={props.email} />
           
{/* 
            <div className="larger-video">
{
    loading ? "Loading"
    :
        <FileDisplay file_details={posts} />
}
            </div>

            <div className="smaller-videos">

<div className="first-video">
                       { 
                       loading ? "Loading"
                       :
                       posts.length >1 ?
                       posts[1].file_type==="video" ? 
                        <Video  playsInline  >

                            <source src={posts[1].file_link} type="video/mp4"/>
                           

                        </Video>
                        :
                        posts[1].file_type==="image" ?
                         <img src={posts[1].file_link} alt="post" /> 
                         :
                         <p>.</p>
                         :
                         <p></p>
                        } 
</div>

<div className="second-video">
{ 
                       loading ? "Loading"
                       :
                       posts.length==3?
                       posts[2].file_type==="video" ? 
                        <Video  playsInline  >

                            <source src={posts[2].file_link} type="video/mp4"/>
                           

                        </Video>
                        :
                        posts[2].file_type==="image"?
                         <img src={posts[2].file_link} alt="post" /> 
                         :
                         <p>.</p>
                         :
                         <p></p>
                        } 
</div>
            </div>
            */}

</>
    )
}

export default AccountPosts