import React from "react";

function LocalContent(){
    return(
        <div>
            LocalContent
        </div>
    )
}

export default LocalContent