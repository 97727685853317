import React, { Fragment } from "react";
import {BiUpvote} from 'react-icons/bi'
import { useState,useEffect,useContext } from "react";
import io from 'socket.io-client'
import {UserContext} from '../App'
import { Link} from "react-router-dom";
import axios from 'axios'
import {AiOutlineHeart} from 'react-icons/ai'
import {AiFillHeart} from 'react-icons/ai'

const socket=io.connect(process.env.REACT_APP_API_URL)

function VoteHandler(props){
    const user=useContext(UserContext)

    const[vote,setVote]=useState(0)
    const[votersEmail,setVotersEmail]=useState('')
    const[postVotingStatus,setPostVotingStatus]=useState('')
    const[votedStatus,setvotedStatus]=useState(false)
   
    
    const handleVotes=()=>{
        user&& 
        socket.emit("getVotes",{vote:vote,post_id:props.post_id,email:user.email})
        
    }



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/posts/${props.post_id}`)
          .then(response => {
            let postvote = response.data.postVote;
            setVote(postvote);
          })
          .catch(error => {
            
          });
      
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/posts/votingStatus?userDetails={"post_id":"${props.post_id}","email":"${user ? user.email : "email"}"}`)
          .then(response => {
            setPostVotingStatus(response.data);
            if (response.data.length > 0 && props.post_id === response.data[0].post_id) {
              
              setvotedStatus(true);
            }
          })
          .catch(error => {
            
          });
      }, []);
      


    useEffect(() => {
        socket.on("sendVotes", (voteData) => {
            if (voteData.post_id === props.post_id) {
                setVote(voteData.numberOfVotes);
                setvotedStatus((prevState)=>!prevState)
            }
        });
        return () => {
            socket.off("sendVotes");
        };
    }, [socket, props.post_id]);
    
 
    
    return(
        <Fragment>
            {user?
            
            <div className="rxns" onClick={handleVotes}>
                {
                    votedStatus
                    ?
                    <AiFillHeart color="#F47325"/>
                    
                    :
                    <AiOutlineHeart color="#000"/>
}
                <div className="count">{vote}

                </div> 
                </div>
            :
            <Link to="/account">

            <div className="rxns" onClick={handleVotes}><AiOutlineHeart color="#000"/><div className="count">{vote}</div> </div>
            </Link>
            }
        </Fragment>
    )
}

export default VoteHandler