import React,{useState} from 'react'
import axios from 'axios';

function UpdateAirtime(){

    const[details,setDetails]=useState({isp:"",codes:"",loading:false})
    


    const HandleSubmit = async (e) => {
        e.preventDefault();
        setDetails({ loading: true });
    
        const giftDetails = {
          isp: details.isp,
          airtime_codes: details.codes
          
        };
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v1/gift/createGift`,
            giftDetails
          );
          
          setDetails({ loading: false, isp: "" , codes: "" }); // Reset the comment input after successful submission
        //   props.setCommentSent(prev => !prev); // Toggle commentSent to trigger re-fetching comments
        } catch (error) {
          setDetails({ loading: false, isp: "" , codes: "" });
        }
      };

    return(
        <div className='update-airtime'>

            <form onSubmit={HandleSubmit}>
                <div className='airtime-fields'>
                <label>ISP</label>
               

                <select id="airtime-select" onChange={e => setDetails({ ...details, isp: e.target.value })}>

                            <option value="select">Select ISP</option>
                            <option value="safaricom">Safaricom</option>
                            <option value="airtel">Airtel</option>
                            <option value="telkom">Telkom</option>

                        </select>
                </div>



                <div className='airtime-fields'>
                <label>Airtime Codes</label>
                <input type="text" onChange={e => setDetails({ ...details, codes: e.target.value })} required/>
                </div>

                <div className='airtime-fields'>
                <button type="submit">
              {
                details.loading ? "Loading...." : "Submit"
              }
            </button>
                </div>
 

            </form>

        </div>
    )
}

export default UpdateAirtime