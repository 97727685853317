import React from "react";
import {Link} from 'react-router-dom'
import {AiFillGift} from 'react-icons/ai'

function MemeBattles(){

    return(
        <div className="meme-battles-container">

            <Link to="/giftbox" className="gift-box"> 
            
            <div><AiFillGift/> 
            </div>
            <h3>Gift Box</h3>
            </Link>

<h1 > –Click the Gift Box for airtime rewards. Stay tuned for exciting updates! Your patience will be rewarded, so keep checking back for the latest news and surprises!</h1>
        </div>
    )
}

export default MemeBattles