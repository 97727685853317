import React, { useEffect, useRef, useState, useContext } from 'react';
import img2 from '../images/meme1.jpg';
import memeVideo from '../videos/meme.mp4';
import memeVideol from '../videos/VideoMeme12003824102022.mp4';
import 'react-html5video/dist/styles.css';
import { DefaultPlayer as Video } from 'react-html5video';
import { BiShareAlt } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import { FaRegCommentAlt } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi'
import { AiOutlineEye } from 'react-icons/ai'
import { BsDownload } from 'react-icons/bs'
import VoteHandler from './VoteHandler';
import CommentModalController from './CommentModalController';
import axios from 'axios';
import { UserContext } from '../App'


function Tv(props) {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [statistics, setStatistics] = useState(false)
  const [uViews, setUViews] = useState([])
  const user = useContext(UserContext)
  const [showPopup, setShowPopup] = useState(false);

  const handleVideoPlay = () => {
    setIsMuted(false);
  };

  const [link, setLink] = useState('');

  const generateLink = () => {
    const baseUrl = window.location.origin; // Get the base URL of the current page
    const generatedLink = `${baseUrl}/postbyid?post_id=${props.post_id}`;
    setLink(generatedLink);
  };

  const copyLink = () => {
    const textArea = document.createElement('textarea');
    textArea.value = link;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setLink('')
  };

  useEffect(() => {
    // Function to update the view count
    const updateViewCount = async () => {
      try {

        const ip = localStorage.getItem('ip');


        // Set a default value if 'country' is not present


        // Encode the 'country' value to ensure it's URL-safe
        const encodedIp = encodeURIComponent(ip);

        // Make an API call to update the view count for the post
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/posts/views`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            post_id: props.post_id,
            user_id: user ? user.email : props.Country,
            userIp: encodedIp

          }),
        });
        // Check the response status and handle accordingly
        if (response.ok) {
          // console.log('View count updated successfully');
        } else {
          // console.log('Failed to update view count');
        }
      } catch (error) {
        // console.error('Error updating view count:');
      }
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
      // If the post becomes visible, update the view count
      if (entry.isIntersecting) {
        updateViewCount();
      }
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [elementRef, props.post_id]);


  const av=async()=>{

    // Function to update the view count
 
      try {

        const ip = localStorage.getItem('ip');


        // Set a default value if 'country' is not present


        // Encode the 'country' value to ensure it's URL-safe
        const encodedIp = encodeURIComponent(ip);

        // Make an API call to update the view count for the post
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/posts/views`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            post_id: props.post_id,
            user_id: user ? user.email : props.Country,
            userIp: encodedIp

          }),
        });
        // Check the response status and handle accordingly
        if (response.ok) {
          // console.log('View count updated successfully');
        } else {
          // console.log('Failed to update view count');
        }
      } catch (error) {
        // console.error('Error updating view count:');
      }


  }


  const cloudinaryUrl = props.file_link;
  const autoOptimizedUrl = cloudinaryUrl.replace('/upload/', '/upload/q_auto/');

  // useEffect(() => {

  //   const fetchUniqueViews = async () => {

  //       try {

  //           const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/statistics/uniqueViews/${props.post_id}`)

  //           setUViews(response.data)


  //       }

  //       catch (err) {
  // console.log(err)

  //       }

  //   }

  //   fetchUniqueViews()

  // }, [])

  const handleDownload = () => {
    // Show the pop-up
    setShowPopup(true);

    // Simulate the download process
    setTimeout(() => {
      // Hide the pop-up after a delay (adjust as needed)
      setShowPopup(false);

      // Perform the download logic here
      const downloadUrl = `${process.env.REACT_APP_API_URL}/downloadFile?fileDetails=${props.file_link}`;
      window.open(downloadUrl, '_blank', 'noopener noreferrer');
    }, 3000); // Simulated delay of 3 seconds
  };

  return (

    <div className="Tv-1">

      <div ref={elementRef} className="monitor">
        {props.file_type === "video" && isVisible ?
          <Video onPlay={handleVideoPlay} playsInline autoPlay muted={isMuted} loop >

            <source src={autoOptimizedUrl} type="video/mp4" />


          </Video>
          :
          props.file_type === "image" ?
            <img src={props.file_link} alt="post" />
            :
            <p>.</p>
        }
      </div>

      <div className="rxns-container">

        <div className="post-reactions">


          <IconContext.Provider value={{ size: "1.5em", color: "#000", padding: ".5em" }} >
            <div className="rxns-parent">

              <div className='views'>
                <AiOutlineEye /><p> {props.views *5} </p>
              </div>


              <CommentModalController post_id={props.post_id} numberOfComments={props.numberOfComments} />

              {/* <VoteHandler post_id={props.post_id} page={props.page} numberOfVotes={props.numberOfVotes} /> */}

              {
                user && user.email === "warigiachegesa@gmail.com"  ? <div className='uViews' onClick={() => setStatistics(true)}>< AiOutlineEye /> {props.uViews} </div> : ""
              }

              {
                statistics && <div onClick={() => setStatistics(false)} className='vote-numbers'>


                </div>
              }




              {/* <a
    href={props.file_link}
    download="your-video-filename.mp4"
  >
   <div className="rxns" ><BsDownload /></div>
  </a> */}

              <button onClick={handleDownload} id='download-btn'>
                <div className="rxns" ><BsDownload /></div>
                {showPopup && (
                  <div className="download-popup">
                    <p>Downloading...</p>
                  </div>
                )}
              </button >

              <div className="rxns" onClick={generateLink}><BiShareAlt /></div>

              {link && (
                <div className='copy-div'>
                  <input type="text" value={link} readOnly />
                  <button onClick={copyLink}><FiCopy size="1em" />Copy Link</button>
                </div>
              )}




            </div>
          </IconContext.Provider>

        </div>

        <div className="tv-speaker">
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>
          <div className="speaker-lines"> </div>

          <div className="speaker-circle">
            <div className="speaker-dot">

            </div>
          </div>
        </div>

      </div>

{
   user && user.email === "warigiachegesa@gmail.com"  ?
<div className='tv-btn' onClick={av}> 

</div>
:
""
}
    </div>
  )
}

export default Tv