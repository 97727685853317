import React, { Fragment, useState, useEffect, useContext } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { UserContext } from "../App";
import axios from "axios";
import img1 from "../images/default.jpeg";

function PostComment(props) {
  const user = useContext(UserContext);
  const [comment, setComment] = useState({ postComment: "", loading: false });

  const commentsHandleSubmit = async (e) => {
    e.preventDefault();
    setComment({ loading: true });

    const encodedComment = encodeURIComponent(comment.postComment);
    const commentDetails = {
      post_id: props.post_id,
      email: user.email,
      comment_text: encodedComment,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/posts/comment`,
        commentDetails
      );
      
      setComment({ loading: false, postComment: "" }); // Reset the comment input after successful submission
      props.setCommentSent(prev => !prev); // Toggle commentSent to trigger re-fetching comments
    } catch (error) {
      setComment({ loading: false, postComment: "" });
    }
  };

  useEffect(() => {
    setComment({ postComment: "" });
  }, [comment.loading]);

  return (
    <Fragment>
      <div className="comment-form">
        <div>
          <img src={img1} alt="profile-img" />
        </div>
        <form onSubmit={commentsHandleSubmit}>
          <div className="textarea">
            <textarea
              value={comment.postComment}
              onChange={(e) => setComment({ postComment: e.target.value })}
              required
            ></textarea>
          </div>
          <div className="btn-submit">
            <button type="submit">
              <AiOutlineSend color="black" fontSize="2em" />
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default PostComment;
