import React from "react";
import HeaderNavigationRoutes from "../HeaderNavigationRoutes";
import GlobalPosts from "../GlobalPosts";

function Global(){

    return(

        <div>
            <HeaderNavigationRoutes />

            <GlobalPosts />

        </div>
    )
}

export default Global