import React from 'react';

const Policies = () => {
  return (
    <div className='policies'>
      <h2>Privacy Policy</h2>
      <p><strong>1. Information We Collect:</strong></p>
      <p>
        At HappyTimesMemes, we collect the following information for authentication purposes:
      </p>
      <ul>
        <li>Email address: We use your email address for authentication and communication purposes.</li>
      </ul>

      <p><strong>2. Consent:</strong></p>
      <p>
        By using our services, you consent to the collection and use of your email address for authentication purposes. You have the right to opt out or withdraw your consent at any time by contacting us at happytimesmemes@gmail.com.
      </p>

      <p><strong>3. Protection of Personal Information:</strong></p>
      <p>
        We are committed to protecting your personal information and will not share your email address with any third party, except as required by law.
      </p>

      <h2>Content Policies</h2>

      <p><strong>1. Prohibited Content:</strong></p>
      <ul>
        <li>
          a. Explicit Sexual Content: We strictly prohibit the upload, sharing, or dissemination of explicit sexual content on our platform. Such content includes, but is not limited to, pornography, sexually explicit images, and explicit text.
        </li>
        <li>
          b. Hate Speech and Harassment: We do not tolerate hate speech, harassment, or content that promotes discrimination based on race, gender, religion, ethnicity, or any other form of discrimination.
        </li>
        <li>
          c. Violence and Harmful Content: Content that promotes violence, self-harm, or harm to others is strictly prohibited.
        </li>
        <li>
          d. Misinformation and Fake News: Sharing false information or engaging in disinformation campaigns is not allowed.
        </li>
        <li>
          e. Copyright Infringement: Users should respect copyright laws and not upload or share copyrighted material without proper authorization.
        </li>
      </ul>

      <p><strong>2. Reporting and Enforcement:</strong></p>
      <p>
        Users can report content that violates our policies through our reporting system. We will review reported content and take appropriate action, which may include removal of the content or suspension of the user's account.
      </p>

      <p><strong>3. Consequences of Policy Violations:</strong></p>
      <p>
        Users who repeatedly violate our content policies may have their accounts suspended or permanently banned from using our platform.
      </p>

      <p><strong>4. Changes to Policies:</strong></p>
      <p>
        We reserve the right to update and modify our privacy and content policies. Users will be notified of any significant changes.
      </p>
    </div>
  );
};

export default Policies;
